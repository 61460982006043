import { navigate } from 'gatsby';
import 'twin.macro'
import { Avatar, Button, IconButton, Refresh, TrashWhite } from 'nzk-react-components';
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';
import useData from '../data/AssignmentsLineup/useData';
import useTemplatesData from '../data/AssignmentTemplate/useData';
import Loader from '../../../UI/Loader';
import DraggableLists from './components/DraggableLists';
import useConfirmModal from '../../../../hooks/useConfirmModal';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  margin-bottom: 15px;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TitleSection = styled.div`
  h2 {
    margin-bottom: 10px;
  }
`

const Title = styled.h2``

const AuthorInfo = styled.div`
  display: flex;
  height: 20px;
  align-items: center;
  > * {
    display: flex;
    height: 20px;
    align-items: center;
    > * { margin: 0 0 0 8px; }
  }
`

const LineupPage = (props: { lineupId: string }) => {
  const [lineup, setLineup] = useState<any>(null)
  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(true)
  const { fetchAssignmentsLineup, deleteAssignmentsLineup } = useData()
  const { fetchAssignmentTemplates } = useTemplatesData()

  const fetch = async (refetch?: boolean) => {
    setLoading(true)
    const data = await fetchAssignmentsLineup(props.lineupId, refetch)
    const templates = await fetchAssignmentTemplates(0,1000,refetch)
    setTemplates(templates)
    setLineup(data)
    setLoading(false)
  }

  useEffect(() => {
    fetch()
  }, [])

  const confirmDelete = useConfirmModal({
    title: "Are you sure?",
    message:
      "There is not going back. Once it's gone, it's gone!",
    onConfirm: () => onDelete(),
    onCancel: () => {},
  })

  const onDelete = async () => {
    await deleteAssignmentsLineup(props.lineupId)
    navigate('/star-tutoring/lineups?refetch=true')
  }

  const available = useMemo(() => {
    if (!lineup || !templates) return []
    const ids = lineup.assignmentTemplates.map(t => t._id)
    return templates.filter(({ _id }) => ids.indexOf(_id) < 0)
  }, [lineup, templates])

  if (loading) return <Loader />

  return <Wrapper>
    <Header>
      <IconButton size='x-small' theme='primary' icon={<Refresh />} onClick={() => fetch(true)}>Refresh</IconButton>
      <Button size='x-small' theme='confirm' onClick={() => navigate(`/star-tutoring/lineups/${props.lineupId}/edit`)}>Edit</Button>
      <div tw='ml-auto'>
        <IconButton size='x-small' theme='red' icon={<TrashWhite />} onClick={confirmDelete}>Delete</IconButton>
      </div>
    </Header>
    { !loading && lineup && <Content>
      <TitleSection>
        <Title>{lineup.default ? '✅ ': ''}{lineup.title}</Title>
        { lineup.default && <div style={{ marginBottom: '6px' }}><b>Default for year group {lineup.yearGroup}</b></div> }
        <AuthorInfo>
          { lineup.updatedBy && <div>Last updated by <Avatar user={lineup.updatedBy} size='25px' /><b>{lineup.updatedBy.name}</b>&nbsp; on <FormattedDate value={lineup.updatedAt} />&nbsp;&nbsp;--&nbsp;&nbsp;</div> }
          <div>Created by <Avatar user={lineup.createdBy} size='25px' /> <b>{lineup.createdBy.name}</b>&nbsp;on <FormattedDate value={lineup.createdAt} /></div>
        </AuthorInfo>
      </TitleSection>
      <DraggableLists lineupId={props.lineupId} linedUp={lineup.assignmentTemplates} available={available} />
    </Content> }
  </Wrapper>
}

export default LineupPage
