import React, { useCallback, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from 'styled-components';
import useData from '../../data/AssignmentsLineup/useData';
import AssignmentTemplateCard from './AssignmentTemplateCard';

const Wrapper = styled.div`
  margin-top: 30px;
  display: grid;
  flex: 1;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  max-height: calc(100vh - 70px);
  > * {
    height: 100%;
    overflow: auto;
  }
`

interface IProps {
  lineupId: string
  available: any[]
  linedUp: any[]
}

const addToArrayAtIndex = (array, index, el) => {
  const newArray = [...array]
  newArray.splice(index, 0, el)
  return newArray
}

const moveFromIndexToIndex = (array, from, to) => {
  const newArray = [...array]
  const [el] = newArray.splice(from, 1)
  newArray.splice(to, 0, el)
  return newArray
}

const removeFromArrayAtIndex = (array, index) => {
  const newArray = [...array]
  newArray.splice(index, 1)
  return newArray
}

const DraggableLists = (props: IProps) => {
  const [linedUp, setLinedUp] = useState(props.linedUp)
  const [available, setAvailable] = useState(props.available)
  const { addAssignmentTemplateToLineup, removeAssignmentTemplateFromLineup } = useData()

  const onDragEnd = useCallback(async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const linedUpCopy = [...linedUp]
    const availableCopy = [...available]
    if (result.source.droppableId === "available" && result.destination.droppableId === "available") {
      // Moved in linedup
      setAvailable(a => {
        return moveFromIndexToIndex(a, result.source.index, result.destination.index)
      })
      // console.log(`Moved available from index ${result.source.index} to index ${result.destination.index}`)
    }
    try {
      if (result.source.droppableId === "lined-up" && result.destination.droppableId === "lined-up") {
        // Moved in linedup
        let newElems: any[] = []
        setLinedUp(a => {
          newElems = moveFromIndexToIndex(a, result.source.index, result.destination.index)
          return newElems
        })
        let after
        if (result.destination.index > 0) after = newElems[result.destination.index - 1]?._id
        await addAssignmentTemplateToLineup(
          result.draggableId,
          props.lineupId,
          after
        )
        // console.log(`Moved from index ${result.source.index} to index ${result.destination.index}`)
      } else if (result.source.droppableId === "available" && result.destination.droppableId === "lined-up") {
        // Added
        setLinedUp(a => {
          return addToArrayAtIndex(a, result.destination.index, available[result.source.index])
        })
        setAvailable(a => {
          return removeFromArrayAtIndex(a, result.source.index)
        })
        // console.log(`Added at index ${result.destination.index}`)
        await addAssignmentTemplateToLineup(
          result.draggableId,
          props.lineupId,
          linedUp[result.destination.index - 1] ? linedUp[result.destination.index - 1]._id : undefined
        )
      } else if (result.source.droppableId === "lined-up" && result.destination.droppableId === "available") {
        // Removed from lineup
        // console.log(`Removed from lineup`)
        setAvailable(a => {
          return addToArrayAtIndex(a, result.destination.index, linedUp[result.source.index])
        })
        setLinedUp(a => {
          return removeFromArrayAtIndex(a, result.source.index)
        })
        await removeAssignmentTemplateFromLineup(
          result.draggableId,
          props.lineupId
        )
      }
    } catch (err) {
      setLinedUp(linedUpCopy)
      setAvailable(availableCopy)
    }
  }, [linedUp, available])

  const grid = 8;

  const getListStyle = useCallback(isDraggingOver => ({
    background: isDraggingOver ? "#FEAFD4" : "#FFFFFF",
    borderRadius: '8px',
    padding: grid,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%'
  }), []);

  const getItemStyle = useCallback((isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: `0 0 ${grid}px 0`,
    ...draggableStyle,
  }), []);

  return <Wrapper>
    <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="lined-up">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {linedUp.map((item, index) => (
            <Draggable key={item._id} draggableId={item._id} index={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <AssignmentTemplateCard template={item} dragging={snapshot.isDragging} />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
    <Droppable droppableId="available">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {available.map((item, index) => (
            <Draggable key={item._id} draggableId={item._id} index={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <AssignmentTemplateCard template={item} dragging={snapshot.isDragging} />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
  </Wrapper>
}

export default DraggableLists
