import React from 'react'
import LineupPage from '../../../../components/pages/StarTutoring/LineupPage'
import StarTutoringLayout from '../../../../components/pages/StarTutoring/StarTutoringLayout'

interface IProps {
  path: string
  params: {
    id: string
  }
}

const Page = (props: IProps) => {
  return <StarTutoringLayout path={props.path}>
    <LineupPage lineupId={props.params.id} />
  </StarTutoringLayout>
}

export default Page
